.liveaccount-box {
  border-radius: 8px;
  background-color: $white;
  max-width: 1479px;
  padding: 40px;

  .form-box {
    border-radius: 8px;
    background-color: $light-gray;
    max-width: 910px;
    margin: 0 auto;
    padding: 40px;
    display: grid;
    grid-template-columns: 35px calc(100% - 70px);
    column-gap: 35px;

    .box {
      margin-bottom: 40px;

      .title {
        font-size: 15px;
        color: $blue;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      /deep/ .el-form-item__content {
        @include rtl-sass-prop(margin-left, margin-right, 0 !important);
      }
    }

    .box-platform,
    .box-type {
      .box-inner {
        ul {
          display: grid;
          grid-template-columns: repeat(auto-fill, 48%);
          column-gap: 4%;
          row-gap: 20px;

          li {
            border-style: solid;
            border-width: $border-width;
            border-color: $border-color;
            border-radius: 6px;
            background-color: $white;
            max-width: 339px;
            height: 75px;
            position: relative;
            cursor: pointer;
            display: flex;

            .img {
              background-repeat: no-repeat;
              background-size: cover;
              background-position: 0 0;
            }

            span {
              font-size: 20px;
              color: $blue;
              font-weight: bold;
              font-style: italic;
              line-height: 1.2;
              align-self: center;
              @include rtl-sass-prop(margin-left, margin-right, 15px);
            }

            &::after {
              position: absolute;
              top: 25px;
              @include rtl-sass-prop(right, left, 30px);
              content: url(~@/assets/images/openAccount/radio01.png);
            }

            &.active {
              background-color: $bottle-blue;

              span {
                color: $white;
              }

              &::after {
                content: url(~@/assets/images/openAccount/radio02.png);
              }
            }
          }
        }
      }
    }

    .box-platform li {
      padding: 0 20px;

      .img {
        width: 53px;
        height: 43px;
        align-self: center;

        &.mt4 {
          background-image: url('~@/assets/images/openAccount/mt4.png');
        }

        &.mt5 {
          background-image: url('~@/assets/images/openAccount/mt5.png');
        }
      }

      &.active {
        .img {
          background-position: -53px 0 !important;
        }
      }
    }

    .box-type {
      li {
        .img {
          width: 93px;
          height: 74px;

          &.standardSTP {
            background-image: url('~@/assets/images/openAccount/type1.png');
          }

          &.rawECN {
            background-image: url('~@/assets/images/openAccount/type2.png');
          }

          &.swapFreeStandardSTP {
            background-image: url('~@/assets/images/openAccount/type3.png');
          }

          &.swapFreeRawECN {
            background-image: url('~@/assets/images/openAccount/type3.png');
          }

          &.STANDARD_STP_CENT {
            background-image: url('~@/assets/images/openAccount/type5.png');
          }

          &.ISLAMIC_STP_CENT {
            background-image: url('~@/assets/images/openAccount/type6.png');
          }

          &.PAMM {
            background-image: url('~@/assets/images/openAccount/type4.png');
          }

          &.pro1000 {
            background-image: url('~@/assets/images/openAccount/type7.png');
          }

          &.RAW_ECN_CENT {
            background-image: url('~@/assets/images/openAccount/type2.png');
          }

          &.ISLAMIC_ECN_CENT {
            background-image: url('~@/assets/images/openAccount/type5.png');
          }

          &.ECN {
            background-image: url('~@/assets/images/openAccount/type8.png');
          }
        }

        &.active .img {
          background-position: -93px 0 !important;
        }
      }
    }

    .box-default ul,
    .box-currency ul {
      display: grid;
      grid-template-columns: repeat(auto-fill, calc((100% - 40px) / 5));
      column-gap: 10px;
      row-gap: 20px;

      li {
        .shadow-inner {
          display: grid;
          position: relative;
          grid-template-columns: 30px calc(100% - 30px);
          cursor: pointer;

          .left {
            align-self: center;
            margin-bottom: 20px;
            content: url(~@/assets/images/openAccount/radio01.png);
          }
        }

        &.active .shadow-inner {
          .left {
            content: url(~@/assets/images/openAccount/radio03.png);
          }
        }
      }
    }

    .box-currency ul li {
      .shadow-inner .right {
        img {
          border-width: 5px;
          border-color: $white;
          border-style: solid;
          border-width: $border-width;
          border-color: $border-color;
          border-radius: 4px;
          background-color: $white;
          width: 84px;
          padding: 5px;
        }

        p {
          font-size: 12px;
          color: rgb(98, 109, 113);
          text-transform: uppercase;
          line-height: 2.249;
          max-width: 84px;
          text-align: center;
        }
      }

      &.active .shadow-inner {
        .right {
          img {
            border-width: 5px;
            border-color: $bottle-blue;
            border-style: solid;
            padding: 0;
          }

          p {
            color: $blue;
          }
        }
      }
    }

    .box-default ul li {
      .shadow-inner {
        .left {
          margin-bottom: 0;
        }

        .right {
          border-style: solid;
          border-width: $border-width;
          border-color: $border-color;
          border-radius: 4px;
          background-color: $white;
          max-width: 84px;
          height: 64px;
          display: flex;
          justify-content: center;

          p {
            font-size: 12px;
            color: rgb(98, 109, 113);
            text-transform: uppercase;
            align-self: center;
          }
        }
      }

      &.active .shadow-inner {
        .right {
          border-width: 5px;
          border-color: $bottle-blue;
          border-style: solid;
          background-color: $bottle-blue;

          p {
            color: $white;
          }
        }
      }
    }

    .box-notes /deep/ .el-input__inner {
      height: 55px;
      border: none;
    }

    .last-box {
      .checkBox {
        margin-bottom: 20px;

        /deep/ .el-checkbox {
          display: contents;
        }

        /deep/ .tnc {
          font-size: 15px;
          line-height: 1.2;
          color: rgb(98, 109, 113);
          display: inline;
          @include rtl-sass-prop(margin-left, margin-right, 10px);

          .red {
            display: inline-block;
            color: $red;
            cursor: pointer;
          }
        }
      }

      /deep/ ul {
        @include rtl-sass-prop(padding-left, padding-right, 50px);

        li {
          font-size: 10px;
          line-height: 2.25;
          color: rgb(98, 109, 113);
          list-style: disc;
        }
      }

      .btn-box {
        text-align: center;

        .btn-blue {
          width: 100%;
          margin-top: 30px;
          max-width: 321px;
        }
      }
    }

    .error {
      color: $light-red;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      display: block;
    }
  }

  /deep/ .success {
    max-width: 904px;

    .steps {
      margin-bottom: 80px;

      img {
        margin-bottom: 0;
        display: inline-block;
      }

      .line {
        display: inline-block;
        border-width: $border-width;
        border-color: $blue;
        border-style: dashed;
        width: 157px;
        margin: 0 20px 0;
      }
    }

    .success-content {
      max-width: 600px;

      p {
        font-size: 15px;
        color: $dark-gray;
        line-height: 1.667;
        text-align: center;
      }
    }
  }
}

/deep/ .el-dialog__body {
  padding: 50px 50px 45px !important;

  p {
    font-size: 15px;
    color: #626d71;
    line-height: 1.8;

    &.red {
      color: $red;
      margin-bottom: 40px;
    }
  }

  .line {
    border-width: 0.59px;
    border-color: rgb(160, 160, 160);
    border-style: dashed;
    margin: 20px 0;
  }

  .btn-box {
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 10%;
    margin-bottom: 20px;
  }
}

@media (max-width: 1200px) {
  .liveaccount-box /deep/ .success .steps .line {
    width: calc((100% - (60px * 3) - (20px * 4)) / 2);
  }
}

@media (max-width: 1024px) {
  .liveaccount-box .form-box {
    padding: 20px;
    grid-template-columns: 35px calc(100% - 50px);
    column-gap: 15px;

    .box-platform,
    .box-type {
      .box-inner ul li span {
        @include rtl-sass-prop(margin-left, margin-right, 0);
        font-size: 14px !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .liveaccount-box {
    padding: 20px;

    .form-box {
      padding: 0;
      background-color: $white;

      .box-platform,
      .box-type {
        .box-inner ul {
          grid-template-columns: repeat(auto-fill, 100%);

          li {
            border-style: solid;
            border-width: $border-width;
            border-color: $border-color;
            border-radius: 6px;
            background-color: $light-gray;
          }
        }
      }

      .box-default ul,
      .box-currency ul {
        grid-template-columns: repeat(auto-fill, calc((100% - 20px) / 3));
      }

      .box-notes /deep/ .el-input__inner {
        border-style: solid;
        border-width: $border-width;
        border-color: $border-color;
        border-radius: 6px;
        background-color: $light-gray;
      }

      .last-box {
        /deep/ ul {
          @include rtl-sass-prop(padding-left, padding-right, 20px);
        }
      }
    }

    /deep/ .success {
      .steps {
        margin-bottom: 20px;

        img {
          width: 25px;
        }

        .line {
          margin: 0 10px;
          width: calc((100% - (25px * 3) - (10px * 4)) / 2);
        }
      }

      .success-content p {
        margin-bottom: 40px;
      }
    }
  }
}

@media (max-width: 400px) {
  .liveaccount-box .form-box .box-default ul,
  .liveaccount-box .form-box .box-currency ul {
    grid-template-columns: repeat(auto-fill, calc((100% - 10px) / 2));
  }
}
