
              @import "@/assets/css/variables.scss";
            
































































































































































































































































@import '@/assets/css/pages/openAccount.scss';

/deep/ .el-steps {
  .el-step:nth-child(1) {
    flex-basis: 165px !important;
  }

  .el-step:nth-child(2) {
    flex-basis: 255px !important;
  }

  .el-step:nth-child(3) {
    flex-basis: calc(100% - 165px - 255px) !important;
  }
}

@media (max-width: 767px) {
  /deep/ .el-steps {
    .el-step:nth-child(1) {
      flex-basis: 170px !important;
    }

    .el-step:nth-child(2) {
      flex-basis: 470px !important;
    }

    .el-step:nth-child(3) {
      flex-basis: calc(100% - 170px - 470px) !important;
    }
  }
}
